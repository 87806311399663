<script setup>
import { useMetaStore } from '~/stores/meta.store'
import { useFiltersStore } from '~/stores/filters.store';
import MobileSearchElement from './partials/filters/element.vue';
import MobileSearchMultiTaps from './partials/filters/multi-taps.vue';
import SearchByListingId from './partials/search-by-listing-id.vue';

const router = useRouter();
const filtersStore = useFiltersStore();
const route = useRoute();



await filtersStore.fetchFilters(route.query.category);
const metaStore = useMetaStore();
const categories=ref([])
categories.value = await filtersStore.fetchCategories();
const selectedCategory=ref({slug: route.query.category || (categories.value.length && categories.value[0].slug)})

const meta = ref({});
metaStore.getMetaTags()
meta.value = metaStore.metaGetter();
metaStore.setMeta(meta.value);

filtersStore.updateFilterUrl();

const resetSelectedFilters = () => {
  filtersStore.resetSelectedFilters();
}

await filtersStore.fetchCount();
const listingCount = computed(() => filtersStore.listings_count);

async function handleFilterChange(option) {
	selectedCategory.value=option;
}
</script>

<template>
  <LayoutAppHead></LayoutAppHead>
  <NuxtLayout name="mobile-base">
    <template #mobile-header>
      <MobileHeader title="Search">
        <template #header-left>
          <mobile-back-button></mobile-back-button>
        </template>
      </MobileHeader>
    </template>

    <div class="px-4">
      <SearchByListingId></SearchByListingId>
      <MobileSearchMultiTaps  v-if="categories.length" :value="selectedCategory" trackBy="slug" :options="categories" @update="handleFilterChange" :filter="filter" :filtersStore="filtersStore"></MobileSearchMultiTaps>

        <template v-for="feature in filtersStore.filter.features" :key="`elm_${feature._id}`">
          <MobileSearchElement :filter="feature" :filtersStore="filtersStore" />
        </template>

      <div class="flex">
      </div>
    </div>

    <template #mobile-footer>
      <div class="fixed bottom-0 bg-white p-4 w-full">
        <div class="flex items-center gap-3">
          <button @click="resetSelectedFilters"
            class="bg-indigo-200 text-indigo-900 rounded-lg p-3 capitalize">reset</button>
          <NuxtLink :to="localePath(`/${filtersStore.filter_url}`)"
            class="bg-indigo-900 text-white rounded-lg p-3 capitalize  w-full text-center">{{ $t('client.show_listings', {
              count: listingCount || 0
            }) }}</NuxtLink>
        </div>
      </div>
    </template>
  </NuxtLayout>
</template>